import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Code Review Tips`}</h1>
    <p>{`I've been participating in code review as an author and a reviewer for quite some time now. These are some things I think about from both the author and the reviewer perspective.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` I use terminology and make references specifically to features of GitHub but this can all be applied when using GitLab, Bitbucket, etc.`}</p>
    <h2><HashLink id="authors" to="/code-review#authors" mdxType="HashLink">{`As an author`}</HashLink></h2>
    <p>{`As software developers, there are code review implications of our actions outside the scope of the actual review. It's important to consider what we do before, during and after the actual code review process.`}</p>
    <h3><HashLink id="coding" to="/code-review#coding" mdxType="HashLink">{`While coding`}</HashLink></h3>
    <p>{`Be mindful of how your changes are organized. Small, atomic commits are best.`}</p>
    <p>{`When I worked with MojoTech our standard practice was to craft code branches in such a way that each commit could be independently deployable and the app would not be broken. Furthermore, the commits told a story. Rarely would we add code in one commit just to remove it in the next. This made PR reviews `}<em parentName="p">{`very`}</em>{` efficient and also made the commit history extremely easy to understand.`}</p>
    <p>{`It's not reasonable to expect this level of discipline on every team. At the consultancy we had teams composed entirely of senior-level developers. Rarely does that team composition happen at product, contracting and enterprise companies. For those teams I'd say, "Please keep the reviewer in mind." This might mean splitting the work for a ticket into multiple PRs to make the review easier.`}</p>
    <h3><HashLink id="pre-pr" to="/code-review#pre-pr" mdxType="HashLink">{`Before opening a PR`}</HashLink></h3>
    <p>{`Once I've finished the development I have a mental checklist that I go through before opening a PR:`}</p>
    <p>{`[ ]`}{` manually test the branch`}</p>
    <p>{`[ ]`}{` review the code changes as if they're being scrutinized by the team`}</p>
    <p>{`[ ]`}{` ensure automated test are written and properly cover the changes`}</p>
    <p>{`[ ]`}{` run all CI checks`}</p>
    <ul>
      <li parentName="ul">{`locally or by opening a draft PR before marking as "ready for review"`}</li>
    </ul>
    <p>{`[ ]`}{` write an adequate PR description`}</p>
    <ul>
      <li parentName="ul">{`describe the changes, any dependent PRs in other repositories, whether there will be follow-up PRs to finish the work for the ticket, screenshots when applicable, etc.`}</li>
    </ul>
    <h3><HashLink id="post-pr" to="/code-review#post-pr" mdxType="HashLink">{`After opening a PR`}</HashLink></h3>
    <p>{`The job isn't done once the PR is open for review.`}</p>
    <p>{`Be open-minded to feedback. There seems to be a natural human tendency to get defensive when someone offers constructive criticism of our code changes. Give reviewers the benefit of the doubt. Sometimes intent can be ambiguous since it's so hard to infer tone in text-based mediums but try not to assume that the folks reviewing your code have malicious intent.`}</p>
    <p>{`In addition to being open minded, it's important to address feedback promptly. Some items are better addressed via a call or slack thread. For situations where PR comments suffice, include all supporting details in dialogue to speed up the code review cycle. Overall, just respect your reviewer's time.`}</p>
    <p>{`Adapt to the feedback when applicable to maximize productivity. There are times when PRs cannot be merged because a blocking issue was identified during review. In this case, try to identify any possibilities to extract unblocked work into a separate PR.`}</p>
    <p>{`Learn from feedback and try not to make the same mistake twice. Early in my career I would write a list of PR feedback that I'd received. I would refer to the list before opening PRs to ensure I didn't overlook anything. Eventually, I didn't need the list anymore.`}</p>
    <h2><HashLink id="reviewers" to="/code-review#reviewers" mdxType="HashLink">{`As a reviewer`}</HashLink></h2>
    <p>{`Reviewing code is essential for building quality software. `}<em parentName="p">{`How`}</em>{` we review code can be the difference between having a clean codebase or a mess.`}</p>
    <h3><HashLink id="timing" to="/code-review#timing" mdxType="HashLink">{`Review timing`}</HashLink></h3>
    <p>{`Code may need to be reviewed multiple times. Don't feel pressure to merge problematic changes just because you missed the issue(s) the first time you reviewed the code.`}</p>
    <p>{`Provide feedback and respond to PRs in a timely manner. Just as authors have a responsibility to respect the reviewer's time, the same goes for reviewers.`}</p>
    <h3><HashLink id="intent" to="/code-review#intent" mdxType="HashLink">{`Understand the intent of the changes`}</HashLink></h3>
    <p>{`The most important aspect of code review is understanding what the desired outcome of the changes are. Be sure to review JIRA tickets or any other sources of background information about the problem being solved.`}</p>
    <h3><HashLink id="changes" to="/code-review#changes" mdxType="HashLink">{`Understand the changes`}</HashLink></h3>
    <p>{`I like to pull the changes locally when reviewing large code updates. Sometimes reviewing in the comfort of my editor is more efficient than trying to navigate the codebase on GitHub.`}</p>
    <p>{`Unless the commit history is atomic, I'll run the following command to squash all commits so I can review the entire git diff at once.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git reset $(git merge-base main $(git branch --show-current))
`}</code></pre>
    <p>{`Another advantage of having the code changes on my machine is that I can verify my code suggestions locally. This saves the author from spending time fixing my broken code snippets.`}</p>
    <p>{`Once the code changes are pulled locally there is a great opportunity to run the code for manual testing. When I worked with Credit Karma we had many contributors per repository. There would be at least two reviewers for every PR; one to confirm that the manual testing passes and one to confirm that the code is adequate.`}</p>
    <h3><HashLink id="helpful" to="/code-review#helpful" mdxType="HashLink">{`Provide helpful feedback`}</HashLink></h3>
    <p>{`Feedback should be constructive. If you're going to criticize, don't just complain. Offer an idea for a solution or links to references.`}</p>
    <p>{`If willing, use emojis to better communicate tone. As stated earlier it's hard to infer tone in text-based mediums. Some folks might take code criticism personally. Emojis can help soften the blow a bit.`}</p>
    <p>{`Look to help create and maintain consistency throughout the codebase. Try to recognize when there are opportunities for new patterns`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` to be introduced and when established patterns erroneously diverge.`}</p>
    <h3><HashLink id="types" to="/code-review#types" mdxType="HashLink">{`Types of feedback`}</HashLink></h3>
    <p>{`I've found it's best to be as objective in my reviews as possible. Do the changes introduce bugs? Do the changes have a high potential to introduce bugs in the future? Are we being consistent? Are the changes covered by automated tests that avoid testing implementation details?`}</p>
    <p>{`In addition to looking for objective downsides, we should be curious when reviewing code. If something isn't clear to you, ask about it. Chances are you aren't the only person who will be confused.`}</p>
    <p>{`Clarification feedback gives the team an opportunity to get on the same page with low overhead. Answering the question often doesn't require the author to spend time revisiting the code. As a bonus, the Q&A leaves great historical context for future contributors.`}</p>
    <p>{`Not all feedback is created equal. Some items are more important than others. It's often beneficial to indicate feedback significance. I use the "nit:" prefix for take-it-or-leave-it items. For example, "nit: we could probably make this logic simpler by replacing the scattered if statements with a map or switch statement."`}</p>
    <p>{`While nitpicks can help improve the quality of the codebase, more time should be spent on the higher severity concerns. I've seen folks use "low:", "medium:", or "high:" prefixes to indicate the severity of feedback items.`}</p>
    <p>{`Last but certainly not least, giving praise when something is done well is equally as important as providing constructive criticism.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`"Patterns" could mean algorithmic patterns, code organization (e.g. directory structure / file naming), code structure, etc.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      